import React, { Component } from "react";

import Layout from "../../components/layout/index";
import SEO from "../../components/seo";
import Content from "../../components/legal/privacy-policy";
import mixpanel from "../../lib/mixpanel/index";

import "../../layouts/index.css";
import styleVars from "../../styles/style-vars";

class TermsAndConditions extends Component {
  componentDidMount() {
    mixpanel.track("TERMS_AND_CONDITIONS_OPENED");
  }

  render() {
    return (
      <Layout>
        <SEO title="Terms and Conditions" disableGoogleCrawler={true} />
        <div className="main-content">
          <div className={`${styleVars.page.xl} mb10`}>
            <div className="pt10 pb10 pt20-ns tc tl-ns pb20-ns">
              <h1 className="pa0 f3 f1-ns center mw-60 fw-bold">
                Terms and Conditions
              </h1>
            </div>
            <div className="mw-60 center pt20-ns pt10 pb20-ns pb10">
              <div>
                <p className="f3-ns f5 pa0 ma0">United States</p>
                <div className="terms-box-shadow pa8 br3 mt5">
                  <a
                    className="f6 f5-ns ma0 db secondary-text-color"
                    href="/terms-and-conditions/us/tou"
                  >
                    Terms Of Use
                  </a>
                  <a
                    className="f6 f5-ns ma0 mt4 db secondary-text-color"
                    href="/terms-and-conditions/us/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  <a
                    className="f6 f5-ns ma0 mt4 db secondary-text-color"
                    href="/terms-and-conditions/us/cookie-policy"
                  >
                    Cookie Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default TermsAndConditions;
